<script setup>

</script>

<template>
  <div class="body">
    <h4>畅听会员服务协议</h4>
    <p>
      欢迎您使用畅听会员产品！畅听会员产品（以下简称“本产品”或“本服务”）是小沃科技有限公司（以下简称“我们”）的产品。为使用本服务，您应当阅读并遵守《畅听会员服务协议》（以下简称为“本协议”）。请您务必审慎阅读、充分理解各条款内容，特别是免除或限制责任的相应条款，以及开通或使用某项服务的单独协议，并选择接受或不接受。免除或限制责任条款可能以加粗形式提示您注意，您应重点阅读。 </p>
    <p>
      除非您已阅读并接受本协议所有条款，否则您无权使用本服务。您对本服务的任何购买或接受赠与等获取行为及登录、查看等任何使用行为即视为您已阅读并同意本协议的约束。 </p>
    <p>
      为向客户提供优质、个性化的服务，包括但不限于提供通信服务、保障通信服务安全、改善服务质量、推介个性化产品等，我公司将遵循合法、正当、必要的原则，按照法律法规规定和/或协议约定使用留存客户个人信息，并妥善保管，严格保密，依法保护客户个人信息，非因下列事由，不对外泄露客户个人信息：
    </p>
    <p>如果您未满18周岁，请在法定监护人的陪同下阅读本协议，并特别注意未成年人使用条款。</p>
    <p>一、【协议的范围】</p>
    <p>1.1【协议适用主体范围】</p>
    <p>本协议是您与我们之间关于您使用本服务所订立的协议。</p>
    <p>1.2【本服务内容】</p>
    <p>
      本协议内容同时包括我们可能不断发布的关于本服务的相关协议、业务规则等内容。上述内容一经正式发布，即为本协议不可分割的组成部分，您同样应当遵守。</p>
    <p>二、【关于本服务】</p>
    <p>畅听会员包月增值服务，指按照我们的指定方式支付一定包月服务费用之后，用户可以享有由小沃或第三方提供的服务，简称为：本服务。
      我们可能会根据不同的产品及服务类型，推出不同的包月增值服务，同时，我们也可能会根据用户的需求、产品及服务类型的变化等，对现有包月增值服务种类进行调整以及不断推出新的包月增值服务种类。我们也可能会在不同时间推出具体不同的服务内容，以不断完善、优化本服务。具体包月增值服务种类及服务内容以相关服务页面公布、实际提供的内容为准。您可以自行根据需要选择相应服务。
      您所享有的本服务的具体内容可能会因为级别、是否年费、开通期限、您选择的具体服务类别等因素而有不同，通常高级别、开通年费服务、开通期限较长等情况下，您将会享有更多的服务，具体以相关服务页面公布、实际提供的内容为准。</p>

    <p>三、权利义务</p>
    <p>3.1 【关于收费】</p>
    <p>
      本服务是我们提供的收费服务，您须在按照本服务的收费标准支付相应费用后，方可使用本服务。此外，您也可以通过接受好友赠送等我们认可的其他方式享有、使用本服务。</p>
    <p>
      我们可能会根据本服务的整体规划，对本服务的收费标准、方式等进行修改和变更，前述修改、变更，我们将在相应服务页面进行展示。您若需要获取、使用本服务，请先提前了解清楚当时关于本服务的收费标准、方式等信息。</p>
    <p>3.2【服务开通】</p>
    <p>3.2.1您应该通过我们的活动入口开通本服务。本服务开通之后，不可进行转让。</p>
    <p>您不得通过以下任何方式为自已或他人开通本服务：</p>
    <p>（1）以营利、经营等非个人使用的目的为自己或他人开通本服务；</p>
    <p>（2）通过任何机器人软件、蜘蛛软件、爬虫软件、刷屏软件等任何程序、软件方式为自己或他人开通本服务；</p>
    <p>（3）通过任何不正当手段或以违反诚实信用原则的方式为自己或他人开通本服务；</p>
    <p>（4）通过非我们指定的方式为自己或他人开通本服务；</p>
    <p>（5）通过侵犯我们或他人合法权益的方式为自己或他人开通本服务；</p>
    <p>（6）通过其他违反相关法律、行政法规、国家政策等的方式为自己或他人开通本服务。</p>
    <p>3.3 【服务期限】</p>
    <p>3.3.1本服务的服务期限以您自行选择并支付相应服务费用的期限为准，您也可以登陆本服务的相应页面查询。</p>
    <p>3.4 【行为规范】</p>
    <p>3.4.1【五不准】</p>
    <p>您在使用本服务时不得利用本服务从事以下行为，包括但不限于：</p>
    <p>
      （1）发布、传送、传播、储存违反国家法律、危害国家安全统一、社会稳定、公序良俗、社会公德以及侮辱、诽谤、淫秽、暴力的内容；</p>
    <p>（2）发布、传送、传播、储存侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的内容；</p>
    <p>（3）虚构事实、隐瞒真相以误导、欺骗他人；</p>
    <p>（4）发表、传送、传播广告信息及垃圾信息；</p>
    <p>（5）从事其他违反法律法规、政策及公序良俗、社会公德等的行为。</p>
    <p>3.4.2【用户禁止行为】</p>
    <p>本服务仅供您个人使用，除非经我们书面许可，您不得进行以下行为：</p>
    <p>
      （1）通过任何方式搜集本服务中其他用户的用户名、电子邮件等相关信息，并以发送垃圾邮件、连锁邮件、垃圾短信、即时消息等方式干扰、骚扰其他用户；</p>
    <p>（2）通过本服务发布包含广告、宣传、促销等内容的信息；</p>
    <p>（3）将本服务再次许可他人使用；</p>
    <p>（4）其他未经我们书面许可的行为。</p>
    <p>3.4.3【对自己行为负责】</p>
    <p>
      您充分了解并同意，您必须为自己注册帐号下的一切行为负责，包括但不限于您所发表的任何内容以及由此产生的任何后果。您应对本服务中的内容自行加以判断，并承担因使用内容而引起的所有风险，包括因对内容的正确性、完整性或实用性的依赖而产生的风险。</p>
    <p>3.5【服务的变更、中止或终止】</p>
    <p>
      您充分了解并同意，由于互联网服务的特殊性，我们可能会按照相关法规、双方约定或在其他必要时，中止或终止向您提供本服务，届时，我们会依法保护您的合法权益。</p>
    <p>四、【违约责任】</p>
    <p>4.1
      如果我们发现或收到他人举报您有违反本协议任何行为的，我们有权独立判断并在不进行任何事先通知的情况下终止向您提供服务，并采取技术手段予以删除、屏蔽或断开相关的信息。同时，我们有权视您的行为性质，对您采取包括但不限于暂停或终止部分或全部本服务。对涉嫌违反法律法规、涉嫌违法犯罪的行为，我们将保存有关记录，并依法向有关主管部门报告、配合有关主管部门调查，并有权追究您相关法律责任。如发生上述情形对您暂停或终止部分或全部本服务的，我们无需向您退还任何费用，由此给您带来的损失（包括但不限于通信中断、相关数据清空等），由您自行承担。
      因您违反本协议或其他协议条款规定，引起第三方投诉或诉讼索赔的，您应当自行处理并承担可能由此引起的全部法律责任。如因您的违法或违约行为导致我们及其关联公司向任何第三方赔偿或遭受监管机关处罚的，我们有权要求您赔偿全部损失。</p>
    <p>五、用户隐私保护</p>
    <p>
      我们尊重并致力于保护您的个人隐私。您可以通过阅读《畅听会员隐私政策》了解我们对您的个人数据的处理方式，以及为您提供的访问和控制您的个人数据的途径。</p>
    <p>六、权利声明</p>
    <p>
      6.1我们对本产品所包含的受知识产权或其他法律法规保护的信息享有相应的法律权利，包括但不限于所有权、知识产权（无论该等权利是否已经登记/注册，亦无论其存在于世界任何地方）及其他相关利益。未经我们书面许可，任何人不得擅自使用（包括但不限于通过任何机器人、蜘蛛等程序或设备监视、复制、传播、展示、镜像、上载、下载）、复制或用作其他用途。</p>
    <p>
      6.2我们为产品开发、运营提供技术支持，并对本产品的开发和运营等过程中产生的所有数据和信息等享有法律法规允许范围内的全部权利。</p>
    <p>
      6.3您在使用本产品时，不得以任何方式对我们和/或其许可人的专有权利造成任何损害，如不得对任何人的商标、名称及标识的所有权人或授权使用人造成混淆的方式使用该等商标、名称或标识等。</p>
    <p>七、不可抗力及责任限制</p>
    <p>
      7.1我们对于您自本产品中获得的所有第三方内容或者广告宣传等任何信息（以下统称“第三方信息”），不保证真实、准确和完整性。如任何单位或者个人通过上述“第三方信息”而进行任何行为，您须自行甄别真伪和谨慎预防风险。</p>
    <p>
      7.2我们将尽商业和技术上的合理努力保证本产品各项功能的正常运转，并合理审慎地采取必要措施维护您的信息和数据安全，但我们不能就此提供完全保证。您理解并同意，在以下情况下，我们无需对您所遭受的损失（包括但不限于财产、收益、数据等方面的损失）承担责任：</p>
    <p>（1）因台风、地震、海啸、洪水、停电、战争、恐怖袭击等不可抗力之因素导致本产品不能正常运作；</p>
    <p>
      （2）由于计算机病毒、木马、其他恶意程序、黑客攻击、电信部门及网络运营公司技术调整或故障、系统维护等原因而造成的本产品服务中断或延迟；</p>
    <p>（3）由于法律法规的变更、司法机关及行政机关等的命令、裁定等原因而导致的本产品服务中断、终止或延迟；</p>
    <p>（4）您违规使用本产品或您因操作不当造成的损失。</p>
    <p>八、其他条款</p>
    <p>
      我们变更本协议的条款时，将会将变更或新增内容以电子邮件、邮件或者在本产品中通知您，并促请您同意或拒绝本协议的修订条款。</p>
    <p>
      8.1本协议条款变更后，如果您继续使用本服务，即视为您已接受修改后的协议。若您不同意变更或新增内容，您有权终止本协议，并停止使用本产品。</p>
    <p>8.2无论基于何种原因，本协议条款部分无效或不可执行，不影响其余条款仍然有效并对您和我们具有法律约束力。</p>
    <p>8.3本协议可能存在中文、英文等多种语言版本，如存在语言版本间的冲突时，以中文版本为准。</p>
    <p>
      8.4因本协议引起的或与本协议有关的任何争议，各方应友好协商解决；协商不成的，任何一方均可将有关争议提交至小沃科技有限公司住所地有管辖权的法院诉讼解决。</p>
    <p>8.5如您对本协议任何条款有任何意见或建议，请随时联系我们，联系方式是：400-011-4006
      。我们将尽快审核所涉问题，并在验证您的用户身份后的十五日内回复。</p>
    <p>自动续费服务规则</p>
    <p>本规则被视为《畅听会员服务协议》的补充协议，是其不可分割的组成部分，与其构成统一整体，如本规则与《畅听会员服务协议》存在冲突的，以本规则为准。如您需使用畅听会员自动续费服务，则需同意本规则。
      您理解并同意：</p>
    <p>1、服务名称：自动续费。</p>
    <p>
      2、本服务是在您已开通畅听会员服务的前提下，为您提供的自动续费服务。如您开通畅听会员服务，则视为您授权畅听会员有权在您的会员服务即将到期时，从您的自有充值账户、与会员账号绑定的第三方支付账户、银行卡、通信账户、iTunes账户（以下统称“账户”）余额中代扣下一个计费周期包月费。该服务实现的前提是您已将畅听会员账号与上述账户绑定，且可成功从上述账户中扣款，因上述账户中可扣款余额不足导致的续费失败，由您自行承担。
      同一个Apple ID只能给一个手机号码开通自动续费服务。</p>
    <p>3、除非您或我们主动明确地取消了自动续费，否则，自动扣款长期有效、不受次数限制。</p>
    <p>4、如果您未主动明确地取消自动续费，我们将在每个自然月月初自动续订并扣除相应费用。</p>
    <p>5、如需取消自动续订，请在月初扣费前操作取消。您可通过以下方式取消自动续订：</p>
    <p>（1）活动页面详情说明中的方式进行退订。</p>
    <p>（2）直接拨打客服电话400-011-4006人工处理。</p>

    <p></p>
    <p></p>

    <div>
      <p>（a）事先获得客户的明确授权。</p>
      <p>（b）根据有关的法律法规要求。</p>
      <p>（c）按照相关司法机关和/或政府主管部门的要求</p>
      <p>（d）为维护社会公众的利益所必需且适当。</p>
      <p @click="()=>{
        console.log(11111)
      }">（e）为维护我公司或客户的合法权益所必需且适当。</p>
    </div>
  </div>
</template>

<style scoped lang="less">
.body {
  padding: 1rem;
  font-size: 0.7rem;

  h4 {
    text-align: center;
    line-height: 2;
    font-size: 0.8rem;
  }

  p {
    line-height: 1.4;
  }
}

</style>
