import axios from 'axios'
import {Toast} from "vant";

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL: '',
    // 超时
    timeout: 20000
})

// request拦截器
service.interceptors.request.use(config => {
    return config
}, error => {
    Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
        console.log(res)
        // 未设置状态码则默认成功状态
        // const code = res.data?.success;
        // // 二进制数据则直接返回
        // if (code) {
        //     return res.data
        // } else {
        //     return res.data
        // }
        if (res.status === 200) {
            return res.data
        } else {
            Toast("请求失败")
        }


    },
    error => {
        return Promise.reject(error)
    }
)

export default service
